
import { mapState } from 'vuex'

export default {
  props: {
    blok: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapState(['user', 'homepageTopAlertContentCards']),
    cards() {
      return [...this.homepageTopAlertContentCards]
    }
  },
  mounted (){
    if(this.cards.length){
      this.$store.commit('logContentCardImpressions', this.cards)
    }
  },
  methods: {
    convertExpirationToChip(expiresAt) {
      let today = new Date()
      let isToday = isToday ? "TODAY" : today.getDate() === expiresAt.getDate()
      let expiresDay = expiresAt.toLocaleString('en-us', { weekday: 'long' })
      let expiresTime = expiresAt.toLocaleString('en-US', { hour: 'numeric', hour12: true })

      return `UNTIL ${expiresTime} ${expiresDay}`
    },
    convertExpirationToTimeRemaining(expiresAt) {
      let today = new Date()
      let remaining = Math.abs(today.getTime() - expiresAt.getTime())

      let dd = Math.floor(remaining / 1000 / 60 / 60 / 24)


      remaining -= dd * 1000 * 60 * 60 * 24;
      let hh = Math.floor(remaining / 1000 / 60 / 60)
      hh = ('0' + hh).slice(-2)

      remaining -= hh * 1000 * 60 * 60;
      let mm = Math.floor(remaining / 1000 / 60)
      mm = ('0' + mm).slice(-2)

      let message = ''
      message += dd > 0 ? `${dd} DAYS ` : ''
      message += hh > 0 ? `${hh} HOURS ` : ''
      message += mm > 0 ? `${mm} MINUTES ` : ''
      return message + 'LEFT'
    }
  }
}
